import React from 'react'
import { SEO } from '../components/UI'
import { InternalHeader, InternalContent, InternalTestimonial, InternalAttendance } from '../components/Internal'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './desenvolvimento-individual-ou-em-grupo.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Desenvolvimento Individual ou em grupo' />
      <InternalHeader withType>
        <small>Online ou Presencial</small>
        <h1>
          Desenvolvimento
          <br />
          <strong>Individual ou em grupo</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent className={styles.terapyContent}>
            <h2>
              Oi, estava
              <br /> esperando <strong>por você!</strong>
            </h2>
            <p>Vamos celebrar a vida?</p>
            <p>
              Estou aqui para te inspirar nessa jornada evolutiva. Te ajudar a entender porque determinada área da tua
              vida está bloqueada, sem fluxo, sem movimento. O que impede tua felicidade afetiva, social, profissional,
              financeira ou de saúde?
            </p>
            <p>
              Acredito que quando temos uma dificuldade, não estamos simplesmente passando por uma experiência ruim,
              estamos sendo convidados pela vida para criar um espaço interno de autoconhecimento e desenvolvimento
              novos conhecimentos, habilidades e atitudes.
            </p>
            <p>
              Para seguir nessa jornada, somos estimulados a buscar na camada mais profunda da nossa Alma, onde estão
              abrigadas nossas raízes, a energia capaz de trazer à tona a força de vida que precisamos para nutrir nosso
              corpo, mente e espírito. Como árvores, mesmo quando nos sentimos “podados”, de nossas raízes ainda nascem
              brotos que vão trazer a vida de volta outra vez.
            </p>
            <h2>Você escolhe</h2>
            <h5>Sessão Online</h5>
            <p>
              Encontro online com horário previamente agendado e feito via plataforma digital de fácil interação e
              manuseio ou vídeo chamada pelo whatsApp. Em qualquer opção que fizer, você terá conforto e tranquilidade.
            </p>
            <h5>Sessão Presencial</h5>
            <p>
              Encontro presencial com horário previamente agendado. Para trabalhos em grupo consulte o desenvolvimento
              em grupo.
            </p>
            <h2>Como faremos</h2>
            <p>
              Você pode me chamar aqui no WhatsApp para nos conhecermos melhor e através deste contato inicial
              desenharmos juntos o melhor caminho para atingir sua meta de desenvolvimento pessoal ou profissional.
            </p>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Sessão de terapia floral sistêmica
              </dt>
              <dd>
                Através das sessões sistêmicas identificamos e resgatamos aspectos que faltam para que possamos nos
                sentir mais feliz. Buscamos identificar os fios invisíveis que norteiam nossos padrões de comportamento,
                e a partir do olhar sistêmico, identificamos os princípios gerais que nos vinculam no sistema familiar e
                determinam o nosso desenvolvimento pessoal e profissional. Do decorrer dos encontros traçamos um projeto
                para transformar os padrões limitantes em metas para uma vida mais plena e realizada. A cada sessão o
                cliente recebe uma fórmula floral desenvolvida especificamente para o tema abordado, contemplando o
                desenvolvimento das competências que irão ajudar no próximo passo.
              </dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Leitura do Campo Emocional
              </dt>
              <dd>
                Com o auxilio da radiestesia, acesso o campo emocional do cliente e faço uma leitura das emoções em
                desequilíbrio que estão bloqueando o seu fluxo energético. Identifico, os potencias que estão latentes e
                precisam ser resgatados para que a Alma siga seu caminho evolutivo trazendo mais saúde, harmonia,
                equilíbrio e bem-estar para o corpo. Junto com a leitura do campo emocional o cliente recebe uma fórmula
                floral desenvolvida especificamente para o tema abordado, contemplando o desenvolvimento das
                competências que irão ajudar no seu desenvolvimento pessoal ou profissional.
              </dd>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Constelação Familiar Individual
              </dt>
              <dd>
                A constelação é uma forma de terapia breve, normalmente uma sessão é suficiente para trazer fluxo no
                aspecto da vida que precise de movimento. É uma forma de terapia que trabalha com as informações
                contidas na nossa Alma familiar, ou seja, nas memórias contidas nas várias gerações que nos antecederam.
                Constelamos para entender porque determinada área da vida está bloqueada, sem fluxo, sem movimento.
                Porque nos sentimos tristes, deprimidos, cansados, sem ânimo...... o que impede nossa realização
                afetiva, social, profissional, financeira, de saúde entre outras.
              </dd>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalAttendance />
            <InternalTestimonial className={styles.testimonial} />
          </div>
        </div>
      </section>
    </>
  )
}
